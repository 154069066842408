

import {
    PEDIDOS_LIST
    , PEDIDOS_ISLOADING

    , PEDIDOS_LIST_NFE
    , PEDIDOS_ISLOADING_NFE
} from '../actions/ActionsTypes';

const INITIAL_STATE = {
    dados:[]
    ,isloading: true
    , xmlnfe: null
    , isloadingnfe: true
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case PEDIDOS_LIST:
            return { ...state, dados: action.payload}
        
        case PEDIDOS_ISLOADING:
            return {...state, isloading: action.payload}

        case PEDIDOS_LIST_NFE:
            return { ...state, xmlnfe: action.payload}
            
        case PEDIDOS_ISLOADING_NFE:
            return {...state, isloadingnfe: action.payload}

        default:
            return state;
    }
}