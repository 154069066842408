import React, { Component } from 'react';

import { withRouter } from 'react-router';

import { Link } from 'react-router-dom';


import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { Icon, Step, Header, Segment, Dimmer, Loader, Image, Container, Grid, List } from 'semantic-ui-react'

import If from '../../utils/If';

import { pedidosListAction } from '../../actions/PedidosActions'


const StyledStep = styled(Step)`
&&& {
    max-width: 175px;
   }  
`


class Rastreamento extends Component {

    componentDidMount() {

        this.props.pedidosListAction(this.props.origem, this.props.codigo)


    }



    render() {


        let { isloading, dados } = this.props;



        return (

            <Container>


                <Grid>
                    <Grid.Column width={4}>
                        <Image src='../img/vcc.png' />
                    </Grid.Column>
                    <Grid.Column width={9}>
                        <br />
                        <Header as='h3' textAlign='center'>
                            <Header.Content>
                                Rastreamento de Pedido
                        <Header.Subheader>
                                    Acompanhe a situação de seu pedido realizado na Você Constrói
                        </Header.Subheader>
                            </Header.Content>
                        </Header>
                    </Grid.Column>
                    
                </Grid>




                <If test={isloading}>
                    <Segment>
                        <Dimmer active inverted>
                            <Loader size='large'>Carregando</Loader>
                        </Dimmer>
                        <Image src='../img/paragraph.png' />
                    </Segment>
                </If>




                <If test={!isloading}>




                    <Segment textAlign="center">
                        {
                            dados.map(item =>
                                <div>
                                    <If test={item.situacao > 0 && item.sitfbits != 4}>
                                        <div>
                                            <Header as='h3' textAlign='center'>
                                                <Header.Content>
                                                    {`Pedido #${item.pedidofbits} ${(item.nf != 0 && item.nf != null ? `NF #${item.nf}` : "")}`}
                                                </Header.Content>
                                                <Header.Subheader>
                                                    {item.cliente}
                                                </Header.Subheader>
                                            </Header>

                                            <Step.Group stackable='tablet'>
                                                <StyledStep >
                                                    <Icon name='warning sign' color="teal" />
                                                    <Step.Content>
                                                        <Step.Title>Pedido Devolvido</Step.Title>
                                                        <Step.Description></Step.Description>
                                                    </Step.Content>
                                                </StyledStep>
                                            </Step.Group>
                                            <br /><br />
                                        </div>
                                    </If>

                                    <If test={item.situacao == 0 && item.sitfbits != 4}>
                                        <div>
                                            <Header as='h3' textAlign='center'>
                                                <Header.Content>
                                                    {`Pedido #${item.pedidofbits} ${(item.nf != 0 && item.nf != null ? `NF #${item.nf}` : "")}`}
                                                </Header.Content>
                                                <Header.Subheader>
                                                    {item.cliente}
                                                </Header.Subheader>
                                            </Header>

                                            <Step.Group stackable='tablet'>
                                                <StyledStep disabled={(item.feitonaopago == 0 ? true : false)} active={(item.feitonaopago == 1 ? true : false)} completed={(item.feitonaopago == 2 ? true : false)}>
                                                    <Icon name='cart arrow down' />
                                                    <Step.Content>
                                                        <Step.Title>Pedido Realizado</Step.Title>
                                                        <Step.Description>{(item.feitonaopago != 2 ? "Aguardando pagamento" : "")}</Step.Description>
                                                    </Step.Content>
                                                </StyledStep>
                                                <StyledStep disabled={(item.pagonaoconfirmado == 0 ? true : false)} active={(item.pagonaoconfirmado == 1 ? true : false)} completed={(item.pagonaoconfirmado == 2 ? true : false)}>
                                                    <Icon name='search' />
                                                    <Step.Content>
                                                        <Step.Title>Pedido em Análise</Step.Title>
                                                        <Step.Description>{(item.pagonaoconfirmado != 2 ? "Aguardando confirmação do pagamento" : "")}</Step.Description>
                                                    </Step.Content>
                                                </StyledStep>
                                                <StyledStep disabled={(item.confirmadosemnf == 0 ? true : false)} active={(item.confirmadosemnf == 1 ? true : false)} completed={(item.confirmadosemnf == 2 ? true : false)}>
                                                    <Icon name='dollar sign' />
                                                    <Step.Content>
                                                        <Step.Title>Pedido Pago</Step.Title>
                                                        <Step.Description>{(item.confirmadosemnf != 2 ? "Aguardando emissão de NF" : "")}</Step.Description>
                                                    </Step.Content>
                                                </StyledStep>

                                                <StyledStep disabled={(item.comnfsemrota == 0 ? true : false)} active={(item.comnfsemrota == 1 ? true : false)} completed={(item.comnfsemrota == 2 ? true : false)}>
                                                    <Icon name='sticky note outline' />
                                                    <Step.Content>
                                                        <Step.Title>Pedido a Faturar</Step.Title>
                                                        <Step.Description>{(item.comnfsemrota != 2 ? "Aguardando despacho" : "")}</Step.Description>
                                                    </Step.Content>
                                                </StyledStep>
                                                <StyledStep disabled={(item.emrotanaoentregue == 0 ? true : false)} active={(item.emrotanaoentregue == 1 ? true : false)} completed={(item.emrotanaoentregue == 2 ? true : false)}>
                                                    <Icon name='shipping' />
                                                    <Step.Content>
                                                        <Step.Title>Pedido Enviado</Step.Title>
                                                        <Step.Description>{(item.emrotanaoentregue != 2 ? "Seu pedido já está a caminho" : "")}</Step.Description>
                                                    </Step.Content>
                                                </StyledStep>
                                                <StyledStep disabled={(item.entregue == 0 ? true : false)} active={(item.entregue == 1 ? true : false)} completed={(item.entregue == 2 ? true : false)}>
                                                    <Icon name='thumbs up outline' />
                                                    <Step.Content>
                                                        <Step.Title>Pedido Entregue</Step.Title>
                                                        <Step.Description>{(item.entregue != 2 ? "Está a caminho!" : "")}</Step.Description>
                                                    </Step.Content>
                                                </StyledStep>
                                            </Step.Group>
                                            <br /><br />
                                        </div>
                                    </If>

                                    <If test={item.situacao == 0 && item.sitfbits == 4}>
                                        <div>
                                            <Header as='h3' textAlign='center'>
                                                <Header.Content>
                                                    {`Pedido #${item.pedidofbits} ${(item.nf != 0 && item.nf != null ? `NF #${item.nf}` : "")}`}
                                                </Header.Content>
                                                <Header.Subheader>
                                                    {item.cliente}
                                                </Header.Subheader>
                                            </Header>

                                            <Step.Group stackable='tablet'>
                                                <StyledStep >
                                                    <Icon name='warning sign' color="teal" />
                                                    <Step.Content>
                                                        <Step.Title>Pedido Cancelado</Step.Title>
                                                        <Step.Description></Step.Description>
                                                    </Step.Content>
                                                </StyledStep>
                                            </Step.Group>
                                            <br /><br />
                                        </div>
                                    </If>

                                </div>
                            )
                        }

                    </Segment>

                </If>




                <Segment style={{ padding: '5em 2em', background: '#f7f7f7' }}>
                    <Container>
                        <Grid divided stackable>
                            <Grid.Row>
                                <Grid.Column width={3}>
                                    <Header as='h4' content='Produtos' />
                                    <List link >
                                        <List.Item as='a' href='https://www.voceconstroi.com.br/pisos?utm_source=rastreio-pisos&utm_medium=linkrastreio&utm_campaign=pisos_rastreio' target='_blank'>Pisos</List.Item>
                                        <List.Item as='a' href='https://www.voceconstroi.com.br/rodapes?utm_source=rastreio-rodapes&utm_medium=linkrastreio&utm_campaign=rodapes_rastreio' target='_blank'>Rodapés</List.Item>
                                        <List.Item as='a' href='https://www.voceconstroi.com.br/forros?utm_source=rastreio-forros&utm_medium=linkrastreio&utm_campaign=forros_rastreio' target='_blank'>Forros</List.Item>
                                        <List.Item as='a' href='https://www.voceconstroi.com.br/drywall?utm_source=rastreio-drywall&utm_medium=linkrastreio&utm_campaign=drywall_rastreio' target='_blank'>Drywall</List.Item>
                                        <List.Item as='a' href='https://www.voceconstroi.com.br/steel-frame?utm_source=rastreio-steel-frame&utm_medium=linkrastreio&utm_campaign=steel-frame_rastreio' target='_blank'>Steel Frame</List.Item>
                                        <List.Item as='a' href='https://www.voceconstroi.com.br/portas?utm_source=rastreio-portas&utm_medium=linkrastreio&utm_campaign=portas_rastreio' target='_blank'>Portas</List.Item>
                                        <List.Item as='a' href='https://www.voceconstroi.com.br/divisorias?utm_source=rastreio-divisorias&utm_medium=linkrastreio&utm_campaign=divisorias_rastreio' target='_blank'>Divisórias</List.Item>
                                        <List.Item as='a' href='https://www.voceconstroi.com.br/ferramentas?utm_source=rastreio-ferramentas&utm_medium=linkrastreio&utm_campaign=ferramentas_rastreio' target='_blank'>Ferramentas</List.Item>
                                    </List>
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    <Header as='h4' content='Ajuda e Suporte' />
                                    <List link >
                                        <List.Item as='a' href='https://www.voceconstroi.com.br/CompraSegura' target='_blank'>Compra Segura</List.Item>
                                        <List.Item as='a' href='https://www.voceconstroi.com.br/DuvidasFrequentes' target='_blank'>Dúvidas Frequentes</List.Item>
                                        <List.Item as='a' href='https://www.voceconstroi.com.br/politicas-de-privacidade' target='_blank'>Políticas de Privacidade</List.Item>
                                        <List.Item as='a' href='https://www.voceconstroi.com.br/TrocaseDevolucoes' target='_blank'>Trocas e Devoluções</List.Item>
                                        <List.Item as='a' href='https://www.voceconstroi.com.br/prazos-e-entrega' target='_blank'>Prazos e Entrega</List.Item>
                                        <List.Item as='a' href='https://www.voceconstroi.com.br/frete' target='_blank'>Frete</List.Item>


                                    </List>
                                </Grid.Column>
                                <Grid.Column width={6} >
                                    <List celled>
                                        <List.Item href='https://www.voceconstroi.com.br/indicacao-instaladores' target='_blank'>
                                            <Image style={{ 'font-size': 25 }} avatar src='https://recursos.voceconstroi.com.br/i/SVG-diversos/precisando-instalador-footer.svg' />
                                            <List.Content style={{ 'color':'#949494' }}>
                                                <List.Header>PRECISANDO DE UM INSTALADOR?</List.Header>
                                                Nós indicamos! Clique aqui.
                                            </List.Content>
                                        </List.Item>
                                        <List.Item href='https://www.youtube.com/channel/UC3b1HBzJbF1xNrbjxF2uGtA' target='_blank'>
                                            <Image style={{ 'font-size': 25 }} avatar src='https://recursos.voceconstroi.com.br/i/SVG-diversos/faca-voce-mesmo-footer.svg' />
                                            <List.Content style={{ 'color':'#949494' }}>
                                                <List.Header>FAÇA VOCÊ MESMO!</List.Header>
                                                Veja vídeos de instalação em nosso canal.
                                            </List.Content>
                                        </List.Item>
                                        
                                    </List>
                                </Grid.Column>
                                <Grid.Column width={4} >
                                    <List celled>
                                        <List.Item>
                                            <Icon name='phone' size="big" />
                                            <List.Content>
                                                <List.Header>Atendimento</List.Header>
                                                (19) 3785-9244
                                            </List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <Icon name='whatsapp' size="big" />
                                            <List.Content>
                                                <List.Header>Whatsapp</List.Header>
                                                (19) 9 9950-3457
                                            </List.Content>
                                        </List.Item>

                                        <List.Item>

                                            <List.Content style={{ 'font-size': 12 }}>

                                                Grupo Esplane Espaços Planejados<br />
                                                CNPJ: 61.740.510/0001-90<br />
                                                IE: 244363739110
                                            </List.Content>
                                        </List.Item>




                                    </List>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Segment>

            </Container>






        )
    }

}

Rastreamento.defaultProps = {
    origem: ''
    , codigo: ''
}
Rastreamento.propTypes = {
    origem: PropTypes.string.isRequired
    , codigo: PropTypes.string.isRequired

}

const mapStateToProps = state => ({
    dados: state.PedidosReducer.dados
    , isloading: state.PedidosReducer.isloading
})

export default withRouter(
    connect(mapStateToProps, {
        pedidosListAction
    })
        (Rastreamento));