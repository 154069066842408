import React, { Component } from 'react';

import { withRouter } from 'react-router';

import { Link } from 'react-router-dom';


import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { Icon, Step, Header, Segment, Dimmer, Loader, Image, Container, Grid, List } from 'semantic-ui-react'

import If from '../../utils/If';

import { pedidosnfeListAction } from '../../actions/PedidosActions'


const StyledStep = styled(Step)`
&&& {
    max-width: 175px;
   }  
`


class Nfe extends Component {

    componentDidMount() {

        this.props.pedidosnfeListAction(this.props.origem, this.props.codigo)
      


    }



    render() {


        let { xmlnfe, isloadingnfe } = this.props;



        return (

           <div>{xmlnfe}</div>

        )
    }

}

Nfe.defaultProps = {
    origem: ''
    , codigo: ''
}
Nfe.propTypes = {
    origem: PropTypes.string.isRequired
    , codigo: PropTypes.string.isRequired

}

const mapStateToProps = state => ({
    xmlnfe: state.PedidosReducer.xmlnfe
    , isloadingnfe: state.PedidosReducer.isloadingnfe
})

export default withRouter(
    connect(mapStateToProps, {
        pedidosnfeListAction
    })
(Nfe));