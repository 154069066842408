import {
    PEDIDOS_LIST
    , PEDIDOS_ISLOADING
    , PEDIDOS_LIST_NFE
    , PEDIDOS_ISLOADING_NFE
} from '../actions/ActionsTypes';

import * as Api from '../utils/API';



export const pedidosListAction = (origem, codigo) => (dispatch) =>
    new Promise(function (resolve, reject) {

        if (codigo.length >= 15) {

            //parceiro = codigo.substr(0, 5)
            //pedido = codigo.substr(5, 10)


            Api.getPedido(origem, codigo).then(dados => {
                if (dados.length > 0) {

                    let DadosTratados = dados

                    /*
                    feitonaopago
                    pagonaoconfirmado
                    confirmadosemnf
                    comnfsemrota
                    emrotanaoentregue
                    entregue
                    */

                    for (let i of DadosTratados) {
                        if (i.feitonaopago > 0) {
                            i.feitonaopago = 2
                            i.pagonaoconfirmado = 1

                        } else if (i.pagonaoconfirmado > 0) {
                            i.feitonaopago = 2
                            i.pagonaoconfirmado = 2
                            i.confirmadosemnf = 1

                        } else if (i.confirmadosemnf > 0) {
                            i.feitonaopago = 2
                            i.pagonaoconfirmado = 2
                            i.confirmadosemnf = 2
                            i.comnfsemrota = 1

                        } else if (i.comnfsemrota > 0) {
                            i.feitonaopago = 2
                            i.pagonaoconfirmado = 2
                            i.confirmadosemnf = 2
                            i.comnfsemrota = 2
                            i.emrotanaoentregue = 1

                        } else if (i.emrotanaoentregue > 0) {
                            i.feitonaopago = 2
                            i.pagonaoconfirmado = 2
                            i.confirmadosemnf = 2
                            i.comnfsemrota = 2
                            i.emrotanaoentregue = 2
                            i.entregue = 1

                        } else if (i.entregue > 0) {
                            i.feitonaopago = 2
                            i.pagonaoconfirmado = 2
                            i.confirmadosemnf = 2
                            i.comnfsemrota = 2
                            i.emrotanaoentregue = 2
                            i.entregue = 2
                        }

                    }

                    dispatch({ type: PEDIDOS_LIST, payload: DadosTratados })
                    dispatch({ type: PEDIDOS_ISLOADING, payload: false })
                } else {
                    dispatch({ type: PEDIDOS_LIST, payload: [] })
                    dispatch({ type: PEDIDOS_ISLOADING, payload: true })
                }
                resolve([])
            })


        } else {
            resolve([])
        }


    }
    )






    export const pedidosnfeListAction = (origem, codigo) => (dispatch) =>
    new Promise(function (resolve, reject) {

    

        if (codigo.length >= 15) {



            Api.getPedidonfe(origem, codigo).then(dados => {
                console.log('######');
                console.log(dados);
                if (dados.length > 0) {
                    dispatch({ type: PEDIDOS_LIST_NFE, payload: dados[0].xmlnfe })
                    dispatch({ type: PEDIDOS_ISLOADING_NFE, payload: false })

                   
                } else {
                    dispatch({ type: PEDIDOS_LIST_NFE, payload: [] })
                    dispatch({ type: PEDIDOS_ISLOADING_NFE, payload: true })
                }
                resolve([])
            })


        } else {
            resolve([])
        }


    }
    )